import React, { useState } from "react";

function App() {
  const [query, setQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [todo, setTodo] = useState("");
  const [todos, setTodos] = useState([
    { content: "Solve challenge to get secret access.", done: false, id: 0 },
  ]);
  const [showSearch, setShowSearch] = useState(false);

  const filteredTodos = todos.filter((todo) => {
    if (searchQuery.length === 0) return true;
    return todo.content.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
  });

  const onSearch = (e) => {
    e.preventDefault();
    setSearchQuery(query.trim());
    setQuery("");
  };
  const addTodo = (e) => {
    e.preventDefault();
    if (todo.trim().length === 0) return;
    setTodos([...todos, { content: todo.trim(), done: false, id: Date.now() }]);
    setTodo("");
  };
  const toggleTodo = (id) => {
    const newTodos = [...todos].map((todo) => {
      if (todo.id === id) todo.done ^= true;
      return todo;
    });
    setTodos(newTodos);
  };
  const removeTodo = (id) => {
    const newTodos = [...todos].filter((todo) => todo.id !== id);
    setTodos(newTodos);
  };
  const removeAll = () => {
    setTodos([]);
  };
  const markAllAsDone = () => {
    const newTodos = [...todos].map((todo) => {
      todo.done = true;
      return todo;
    });
    setTodos(newTodos);
  };
  const toggleSearch = () => {
    setShowSearch(!showSearch);
  };
  return (
    <div className="container">
      <div className="logo">
        <img src="/logo.svg" alt="01000101.xyz's Logo" />
      </div>
      <p style={{ marginTop: 15, marginBottom: 15 }}>A nice to-do app.</p>

      <h2>Controls</h2>

      <form onSubmit={addTodo} style={{ marginTop: 15, marginBottom: 15 }}>
        <div className="input-group" style={{ marginBottom: 10 }}>
          <label htmlFor="todo" aria-label="Add todo">
            <i aria-disabled={true} className="material-icons-round">
              list
            </i>
          </label>
          <input
            type="text"
            id="todo"
            name="todo"
            autoComplete="off"
            placeholder="Create new to-do"
            value={todo}
            onChange={(e) => setTodo(e.target.value)}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flexWrap: "wrap",
          }}
        >
          <button className="btn success" type="button" onClick={markAllAsDone}>
            <i className="material-icons-round">check</i>
            <span>Mark all as done</span>
          </button>

          <button className="btn danger" type="button" onClick={removeAll}>
            <i className="material-icons-round">delete_outline</i>
            <span>Delete all</span>
          </button>

          <button className="btn round" type="submit">
            <i className="material-icons-round">add</i>
          </button>
        </div>
      </form>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2 style={{ marginTop: 15, marginBottom: 15 }}>To-dos</h2>

        <button className="btn round info" type="button" onClick={toggleSearch}>
          <i className="material-icons-round">search</i>
        </button>
      </div>

      {showSearch && (
        <form onSubmit={onSearch} style={{ marginTop: 10 }}>
          <div className="suggestions-box-trigger input-group info">
            <label htmlFor="search" aria-label="Search">
              <i aria-disabled={true} className="material-icons-round">
                search
              </i>
            </label>
            <input
              type="search"
              id="search"
              name="query"
              autoComplete="off"
              placeholder="Search existing to-dos"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <button type="submit">
              <i className="material-icons-round">arrow_forward</i>
            </button>
          </div>
        </form>
      )}

      {searchQuery.length > 0 && (
        <span className="tag" style={{ marginTop: 10 }}>
          <span>Showing results for: {searchQuery}</span>
          <button onClick={() => setSearchQuery("")}>
            <i className="material-icons-round">close</i>
          </button>
        </span>
      )}

      {(filteredTodos.length > 0 && (
        <ul className="todos" style={{ marginTop: 15 }}>
          {filteredTodos.map((todo) => (
            <li key={todo.id} className={`todo ${todo.done ? "done" : ""}`}>
              <label>
                <input
                  type="checkbox"
                  checked={todo.done}
                  onChange={(e) => toggleTodo(todo.id)}
                />
                <i className="material-icons-round">
                  {todo.done ? "check_box" : "check_box_outline_blank"}
                </i>
                <span>{todo.content}</span>
              </label>

              <button
                className="btn round danger"
                type="button"
                onClick={() => removeTodo(todo.id)}
              >
                <i className="material-icons-round">delete_outline</i>
              </button>
            </li>
          ))}
        </ul>
      )) || (
        <div className="todos" style={{ marginTop: 15 }}>
          <div className="todo done">
            <div className="label" style={{ cursor: "default" }}>
              <i className="material-icons-round">done_all</i>
              <span style={{ textDecoration: "none" }}>
                Looking empty here... Add something nice.
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
